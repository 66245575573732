import React from "react"
import BlogLayout from 'container/BlogLayout'
import ArticleCard from 'components/ArticleCard'
import { graphql } from "gatsby"
import HeroAction from 'components/HeroAction'
import EmailPicker from 'components/EmailPicker'
import BlogCard from 'components/BlogCard'

const Tags = ({ pageContext, data }) => {
  const { edges } = data.allMarkdownRemark
  const hero = require('data/hero.json')
  return (
    <BlogLayout category={''}>
    <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 md:justify-start mt-6'>
      <div className="flex space-x-6">
        <div className='grow max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-end justify-start'>
          <div className="lg:py-24 mr-6">
            <HeroAction hero={hero} />
            <div className="mt-10 sm:mt-12">
              <EmailPicker email={hero.email} />
            </div>
          </div>
        </div>
        {
          edges.map(edge => {
            if(edge.node.frontmatter.featured)
            return (
              <div className='flex-none w-64'>
                <BlogCard frontmatter={edge.node.frontmatter}/>
              </div>
            )
          }).filter(node => node!=null && node).slice(0,2)
        }
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 justify-items-start py-10 gap-x-16 gap-y-8 border-b border-grey-500'>
        {edges.map(edge => {
          return (
            <div>
              <ArticleCard frontmatter={edge.node.frontmatter} blog={true}/>
            </div>
          )
        })}
      </div>
    </div>
  </BlogLayout>
  )
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            category
            link
            title
            heroImage
            description
            featured
            tags
            type
          }
        }
      }
    }
  }
`