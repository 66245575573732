import React from 'react';
import { navigate } from 'gatsby'
import { slugToText } from '../../utils/util'

export default function BlogCard(props) {
  return <div className=''>
  <div class='cursor-pointer' onClick={() => {
      const link = props.frontmatter.type==='academy' ? `/academy/${props.frontmatter.category}` : `/blog/${props.frontmatter.link}`;
      return navigate(link)}}>
      <div className='mt-12'>
        <div className='relative'>
          <img className='rounded-md object-cover border-0 aspect-[7/11]' src={props.frontmatter.heroImage} alt='Article image'/>          
          <div className='grid grid-flow-col auto-cols-max absolute bottom-0 left-0 ml-2 mb-1'>
            {props.frontmatter.tags && props.frontmatter.tags.map((tag, i) => (
              i == 0 && <div className='bg-white px-4 py-1 rounded-sm text-center hover:drop-shadow-lg'>
                <a href={`/blog/tags/${tag}`} className='text-[11px] font-medium uppercase tracking-widest'>{slugToText(tag)}</a>
            </div>))}
          </div>
        </div>
        <div className='pt-4'>
          <div className='font-medium text-md hover:underline'>{props.frontmatter.title}</div>
        </div>
      </div>
  </div>
</div>;
}
